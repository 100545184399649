<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      height="70"
      class="header"
      v-show="$route.name !== 'Login'"
    >
      <router-link class="d-flex align-center" :to="{ name: 'Groups' }">
        <v-img
          alt="Danone Logo"
          class="shrink mr-2"
          contain
          src="@/assets/header/logo.svg"
          width="155"
        />
      </router-link>

      <v-spacer></v-spacer>

      <v-btn text class="link" color="rgb(0,0,0,0.58)" :to="{ name: 'Groups' }">
        <span>Группы</span>
      </v-btn>
      <v-btn
        text
        class="link"
        color="rgb(0,0,0,0.58)"
        :to="{ name: 'Contractors' }"
      >
        <span>Контрагенты (ЭДО)</span>
      </v-btn>

      <v-btn
        text
        class="link"
        color="rgb(0,0,0,0.58)"
        :to="{ name: 'Reconciliations' }"
      >
        <span>Контрагенты (Сверка)</span>
      </v-btn>

      <v-btn
        v-show="$route.name !== 'Login'"
        text
        color="black"
        @click="isLoggedIn ? logout() : $router.push({ name: 'Login' })"
      >
        <span>{{ isLoggedIn ? "Выйти" : "Войти" }}</span>
      </v-btn>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoggedIn() {
      return this.$store.getters["authorization/isLoggedIn"];
    },
  },
  methods: {
    logout() {
      // выход из уч. записи
      this.$store
        .dispatch("authorization/logout")
        .then(() => this.$router.push({ name: "Login" }))
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import url("~@/styles/normalize.scss");
.v-overlay {
  margin-top: 70px;
  &__scrim {
    // opacity: 0 !important;
    background-color: rgba(108, 108, 108, 0.15) !important;
  }
}
.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  z-index: 200 !important;
}
.link {
  &.v-btn--active {
    color: #006fc0 !important;
  }
  &::before {
    background-color: transparent !important;
  }
  &.v-btn--active::before {
    background-color: transparent !important;
  }
  &:hover.v-btn--active {
    color: #4b99d2 !important;
  }
  &:hover {
    color: #4b99d2 !important;
    background: transparent;
  }
}
body,
.main {
  background: #eff2f5;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
</style>
