<template>
  <v-row align="center" justify="center" class="page-content">
    <v-col align="center" justify="center" class="left-panel">
      <transition name="form-fade">
        <div v-if="showForm">
          <div class="logo">
            <v-img
              alt="Danone Logo"
              class="shrink mr-2"
              contain
              src="@/assets/login-logo.svg"
              width="190"
            />
          </div>
          <h1 class="login-title">Добро пожаловать в систему управления</h1>
        </div>
      </transition>
    </v-col>
    <v-col align="center" justify="center" class="right-panel">
      <v-row align="center" justify="center">
        <transition name="form-fade"><LoginForm v-if="showForm" /></transition>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",
  data() {
    return {
      showForm: false,
    };
  },
  components: {
    LoginForm,
  },
  created() {
    setTimeout(() => (this.showForm = true), 500);
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh !important;
}
.login {
  position: relative;
}
// плавное появление logo
.logo-fade-enter-active,
.logo-fade-leave-active {
  transition: transform 0.5s;
}
.logo-fade-enter {
  transform: translateY(200px);
}
// плавное появление формы логина
.form-fade-enter-active,
.form-fade-leave-active {
  transition: opacity 0.5s;
}
.form-fade-enter {
  opacity: 0;
}

.left-panel,
.right-panel {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-panel {
  background-color: #ffffff;
}

.left-panel {
  background-color: #1aadeb;
}

.login-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  max-width: 533px;
  color: #f7f7f7;
  margin: 0;
}
</style>
