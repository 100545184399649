import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/groups",
    name: "Groups",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/Groups.vue"),
  },
  {
    path: "/categories/:id",
    name: "Categories",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/Categories.vue"),
  },
  {
    path: "/questions/:id",
    name: "Questions",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/Questions.vue"),
  },
  {
    path: "/contractors",
    name: "Contractors",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/Contractors.vue"),
  },
  {
    path: "/reconciliations",
    name: "Reconciliations",
    meta: {
      requireAuth: true,
    },
    component: () => import("../views/Reconciliations.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // если авторизован и идешь на страницу входа, отправляет на главную
  if (to.name == "Login" && store.getters["authorization/isLoggedIn"])
    next({ name: "Groups" });
  else {
    // если нужна авторизация и не авторизован, отправить на стр. входа
    if (
      to.matched.some((record) => record.meta.requireAuth) &&
      !store.getters["authorization/isLoggedIn"]
    )
      next({ name: "Login" });
    else next();
  }
});
export default router;
